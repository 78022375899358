import { initializeApp } from 'firebase/app';
import { getMessaging, getToken as getWebToken, isSupported } from 'firebase/messaging';
import { useDispatch } from 'react-redux';

const firebaseConfig = {
  apiKey: 'AIzaSyBskJKy6v1tzqJDvq6Nt8hRqkaRyAe3vOQ',
  authDomain: 'rentir-notifications.firebaseapp.com',
  projectId: 'rentir-notifications',
  storageBucket: 'rentir-notifications.firebasestorage.app',
  messagingSenderId: '1001700835189',
  appId: '1:1001700835189:web:44d009595763b86d2fdd3c',
  measurementId: 'G-WQF0M7CT59',
};
const vapidKey =
  'BKWQGZXu2szmTJBGbupWRMZ8wO-F_Fof7QKnUXjfCD2-8hs_PhZhCDnbw-YOD_Qn9CUcAOyW55GFV-tPszHBYVg';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const FirebaseMessaging = typeof window !== 'undefined' && window?.Capacitor?.Plugins?.FirebaseMessaging;

export function registerPushNotifications(saveToken) {
  function requestPermission() {
    if (FirebaseMessaging) {
      return FirebaseMessaging.requestPermissions().then((result) => result.receive);
    }
    return Notification.requestPermission();
  }
  async function getToken() {
    if (FirebaseMessaging) {
      const { receive } = await FirebaseMessaging.checkPermissions();
      if (receive !== 'granted') {
        await FirebaseMessaging.requestPermissions();
      }
      return FirebaseMessaging.getToken().then((result) => result.token);
    }
    const messaging = getMessaging(app);
    return getWebToken(messaging, { vapidKey });
  }

  isSupported().then(isSupported => {
    if (!isSupported && !FirebaseMessaging) {
      console.log('Notifications are not supported');
    } else {
      getToken()
        .then(currentToken => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log('currentToken:', currentToken);
            saveToken(currentToken);
          } else {
            // Show permission request UI
            console.log('Requesting permission...');
            requestPermission().then(permission => {
              console.log('Permission:', permission);
              if (permission === 'granted') {
                console.log('Notification permission granted.');
                registerPushNotifications(saveToken);
              }
            });
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
    }
  });
}

export function useSaveToken() {
  const dispatch = useDispatch();
  return token => dispatch(saveToken(token));
}

export function saveToken(token) {
  return (dispatch, getState, sdk) => {
    sdk.currentUser
      .updateProfile({
        privateData: { pushToken: token },
      })
      .then(() => {
        console.log('Token saved');
      })
      .catch(err => {
        console.error('Error saving token:', err);
      });
  };
}
